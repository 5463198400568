import Vue from 'vue'
import VueRouter from 'vue-router'
// 获取User-Agent信息
const userAgent = navigator.userAgent.toLowerCase();
let home = null;
// 判断是否为手机端
if (/(iphone|ipad|ipod|android|mobile|Huawei)/i.test(userAgent)) {
    // 手机端逻辑
    home = import('../views/mobile/index.vue')
} else {
    // PC端逻辑
    home = import('../views/firstPage.vue')
}

Vue.use(VueRouter)

const routes = [{
    path: '/', name: 'firstPage', component: () => home
}, {
    path: '/video', name: 'VideoPlayer', component: () => import('../views/video.vue')
}, {
    path: '/invite', name: 'inviteView', component: () => import('../views/invite.vue')
}, {
    path: '/author', name: 'authorView', component: () => import('../views/author.vue')
}, {
    path: 'feedback', name: 'feedbackView', component: () => import('../views/feedback.vue')
}, {
    path: '/about', name: 'aboutView', component: () => import('../views/about.vue')
}, {
    path: '/release', name: 'releaseView', component: () => import('../views/release.vue')
}, {
    path: '/login', name: 'loginView', component: () => import('../views/login.vue')
}, {
    path: '/test', name: 'testView', component: () => import('../views/test.vue')
}]

const router = new VueRouter({
    mode: 'hash', base: process.env.BASE_URL, routes, scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {selector: to.hash};
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return {x: 0, y: 0};
        }
    }
})

export default router
